import React, { useContext, useState } from 'react'
import {useHistory,useLocation} from 'react-router-dom'

import { createBrowserHistory } from 'history';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { Navbar,NavDropdown,Nav } from 'react-bootstrap';
import DispatchContext from "../../DispatchContext"
import StateContext from "../../StateContext"
import '../../scss/header.scss'
import '../../scss/responsive.scss'

import apiClient from '../../api'

export default function Header(props) {
    let history = createBrowserHistory()
    let location = useLocation()
    const appDispatch = useContext(DispatchContext)
    const [navExpanded,setNavExpanded]=useState(false)
    const appState = useContext(StateContext)
    const [state, setState] = useState({
        isPaneOpenNew: false,
        isPaneOpenLeftNew: false,
      });
    function setsignOut()
    { 
        apiClient.post('logout').then(logoutResponse=>{
            // appDispatch({ type: "flashMessages", data: response.data.message})
            appDispatch({ type: "logout" })
            toggleHeaderMenu();
        })
    }

    const toggleHeaderMenu = () => {
        setNavExpanded(false);
        handleNavToggle(false);
    }
    const handleNavToggle = (tog) => {
        if (document.getElementById('products-list-main-wrapper') !== null) {
        if (tog) {
            document.getElementById('products-list-main-wrapper').style.display = "none";
        } else {
            document.getElementById('products-list-main-wrapper').style.display = "block";
        }
        }
        if (document.getElementsByClassName('ReactModalPortal').length) {
            if (tog) {
                document.getElementsByClassName('ReactModalPortal')[0].style.display = "none";
            } else {
                document.getElementsByClassName('ReactModalPortal')[0].style.display = "block";
            }     
        }
        if (document.getElementsByClassName('page-main-wrapper').length) {
            if (tog) {
                document.getElementsByClassName('page-main-wrapper')[0].style.display = "none";
            } else {
                document.getElementsByClassName('page-main-wrapper')[0].style.display = "block";
            }     
        }
        setNavExpanded(tog);
    }
    return (
        <>
           <section className="top_header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                        <a href="/products/search" className="filter_icon">
                            <span>Filter</span> <img src="/assets/img/search.svg" srcset="/assets/img/search.svg 1x, /assets/img/search.svg 2x"></img> 
                        </a>
                            <Navbar expand="lg" sticky="bottom" onToggle={(tog)=>{handleNavToggle(tog)}} expanded={navExpanded}>
                                <Navbar.Brand>
                                    <Nav.Link className="logo" href="/"><img src="/assets/img/logo.svg" className="d-inline-block align-middle" alt=" "></img></Nav.Link>
                                    <Nav.Link className="logo new_logo" href="/products"><span>DIGITAL FABRIC LIBRARY</span></Nav.Link>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                                    <Nav className="mr-sm-2">
                                    {
                                        props.loggedIn ?
                                        <>
                                        <Nav.Link onClick={() => {toggleHeaderMenu()}} className="search_nav" href="/products/search">Search</Nav.Link>
                                         <Nav.Link as={Link} onClick={() => {toggleHeaderMenu()}} to="/favourites">Favourites</Nav.Link>
                                         <Nav.Link as={Link} onClick={() => {toggleHeaderMenu()}} to="/my-profile">My Account</Nav.Link>
                                         <Nav.Link href="" onClick={setsignOut}><span>|</span><span className="logo_out">Log Out</span> <img src="/assets/img/power-button.svg" className="d-inline-block align-middle logout_btn"  alt="Logout" title="Logout"></img></Nav.Link>
                                         </>
                                        : ""
                                    }
                                        <Nav.Link href="https://workingmenblues.com/" target="_blank">back to WORKINGMENBLUES</Nav.Link>
                                        <Nav.Link href="https://workingmenblues.com/terms-conditions/" className="search_nav" target="_blank">T&C</Nav.Link>
                                        {/* <a href="https://workingmenblues.com/" target="_blank">back to WORKINGMENBLUES</a> */}
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
           </section>
        </>
    )
}
