import React,{ useState, useContext } from 'react'

import { Form,Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import apiClient from '../../api';
import DispatchContext from "../../DispatchContext"
import '../../scss/signforms.scss' 
import { Link } from 'react-router-dom';

export default function Signin(props) {
    const appDispatch = useContext(DispatchContext) 
    const [responseStatus, setResponseStatus] = useState([])
    const [isLoading, setLoading] = useState(false);
    let { register, handleSubmit, errors } = useForm();
        const onSubmit = async data => { 
            setLoading(true)
            apiClient.get('sanctum/csrf-cookie').then(response => {
                apiClient.post('api/login',data).then(response => {
                    if (response.data.status == 200) {
                        setTimeout(() => {
                            appDispatch({ type: "login", data: response.data.userInfo })
                            setLoading(false)
                        }, 1000);
                    } else {
                        setResponseStatus({ type: 'alert-danger', message: response.data.message });
                        appDispatch({ type: "flashMessages", data: "" })
                        setLoading(false)
                    }
                    
                }); 
            })
    } 
    return (
        <>
            <div className="signin home_sign">
                {/* {
                    isLoading ? 
                    <div className="loaderDiv">
                        <img src="assets/img/ajax-loader.gif"></img>
                    </div>
                    : ""
                } */}
                <div className="signin_form"> 
                    
                    <p className="signin_p">Sign in</p>
                    
                    {
                        props.flashMessages && props.flashMessages.length ? <p className="error-disp new_error_msg">{props.flashMessages}</p> : <p>Welcome back!</p>
                    }
                    {
                        responseStatus.message && responseStatus.message.length > 0 ?
                        <p className="error-disp"> {responseStatus.message} </p>
                        : ""
                    }
                    
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Email"  name="email" ref={register({ required: true })}/>
                            {errors.email && <span className="error-disp">Email field is required</span>}
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="Password"  name="password" ref={register({ required: true })}/>
                            {errors.password && <span className="error-disp">Password field is required</span>}
                        </Form.Group>
                        <Button className="login_btn" variant="primary" type="submit">Log in</Button>
                    </Form>
                    <div className="or">
                        Or
                    </div>
                    <Link to={"/forgot-password"} className="forgot_a">Forgot password</Link>
                </div>
            </div>
        </>
    )
}
