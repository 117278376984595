import React,{useState,useEffect} from 'react';
import { Card,Accordion } from 'react-bootstrap';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import apiClient from "../../../api"

import $ from 'jquery'

export default function ProductFilters(props) {

    const initweightRange = [0,50];
    const initWidthRange = [0,70];
    const [ dbFilters, setDbFilters] = useState({});
    const [weightRange,setweightRange] = useState(initweightRange);
    const [widthRange,setWidthRange] = useState(initWidthRange);
    const [Checked, setChecked] = useState({
        highlight:[],
        season:[],
        material: [],
        characteristics: [],
        design: [],
        color: [],
        composition: [],
        weight: [],
        width: [],
        search_keywords: [],
    })
    const [filterValues, setFilteralues] = useState([])

    const [isSmallDevice, setIsSmallDevice] = useState(false)
    
    useEffect(()=>{

        if(props.Filters.search_keywords[0] && props.Filters.search_keywords[0].length > 0){
            Checked.search_keywords[0] = props.Filters.search_keywords[0]
        }
    })
    const handleToggle = (field,value,text) => {
        
        let inputFilter = Checked[field];
        const currentIndex = inputFilter.indexOf(value);
        if (currentIndex == -1) {
            inputFilter.push(value)
        } else {
            inputFilter.splice(currentIndex, 1)
        }
        const newFiedlData = { ...Checked, [field]: inputFilter};
        setChecked(newFiedlData);
        props.setFilters(Checked)
        filterValues.push({ field: field, id: value, text: text });
        props.setFilterData(filterValues)
    }
    useEffect(() => {
        getProductFilters();
        props.setFilters(props.Filters);
    },[]);
    async function getProductFilters(variables){
        apiClient.get('api/product-filters')
            .then(response => {
                if(response.status == 200 && typeof(response.data.filters) !='undefined')
                {
                    const resFilters = response.data.filters;
                    
                    arrangeFiltersIntoSingle(resFilters)
                    setDbFilters(resFilters);
                    props.setTotalFilters(resFilters)

                }else{
                    alert('Products could not be fetched');
                    return;
                }
            });
    }

    function arrangeFiltersIntoSingle(filtersmultidata)
    {
        var getEachData = [];
        if(filtersmultidata.material && filtersmultidata.material.length){
            filtersmultidata.material.map((value) => {
               
                props.filtersMultiValues.push(value)
            })
        }
        if(filtersmultidata.highlight && filtersmultidata.highlight.length){
            filtersmultidata.highlight.map((value) => {
                props.filtersMultiValues.push(value.title)
            })
        }
        if(filtersmultidata.season && filtersmultidata.season.length){
            filtersmultidata.season.map((value,index)=>{
                props.filtersMultiValues.push(value)
            })
        }
        if(filtersmultidata.characteristics && filtersmultidata.characteristics.length){
            filtersmultidata.characteristics.map((value)=>{
                props.filtersMultiValues.push(value)
            })
        }
        if(filtersmultidata.design && filtersmultidata.design.length){
            filtersmultidata.design.map((value) => {
                props.filtersMultiValues.push(value)
            })
        }
        if(filtersmultidata.color && filtersmultidata.color.length){
            filtersmultidata.color.map((value)=>{
                props.filtersMultiValues.push(value)
            })
        }
        if(filtersmultidata.composition && filtersmultidata.composition.length){
            filtersmultidata.composition.map((value)=>{
                props.filtersMultiValues.push(value)
            })
        }
        var allCategories = [];
        props.filtersMultiValues.map((fil, key) => {
        allCategories.push({name: fil})
        // allCategories.push({id: key, name: fil})
        })
        props.setFiltersMultiValues(allCategories)
    }

    useEffect(() => {
        console.log("height")
        console.log(window.innerHeight)
        if (window.innerHeight < 657) {
            setIsSmallDevice(true)
        } else {
            setIsSmallDevice(false)
        }
    }, [])
     
    return (

    
        <div className={`product_left${props.gapRequired ? "_gap" : ""} filter_sec ${isSmallDevice ? "m-h-429" : "m-h-649"}` } style={{ overflowY: "auto" }}>
                        
                        <style jsx>{`
                            .m-h-429{
                                max-height: 257px;
                            }
                            .m-h-649{
                                max-height: 649px;
                            }
                        `}</style>            
            <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                        Highlight {props.setTotalFilters}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul className="filter_ul filter-scroll-class">
                                            {   
                                            Object.keys(dbFilters).length && dbFilters.highlight ? dbFilters.highlight.map((highlight,index)=>{
                                                return (
                                                    <li  className={ Checked['highlight'][index] == index ? "active" : "" }  onClick={()=>{handleToggle('highlight',index, highlight.title)}} key={index} className={highlight.class}>{highlight.title}</li>
                                                );
                                            }) : ''}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                Season
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ul className="filter_ul_other filter-scroll-class">
                                    {   
                                            Object.keys(dbFilters).length && dbFilters.season ? dbFilters.season.map((seaSon,index)=>{
                                                return (
                                                    <li className={ Checked['season'].indexOf(index) == -1 ? "" : "active" } onClick={()=>{handleToggle('season',index,seaSon)}} key={index} >{seaSon}</li>
                                                    // <li className={ Checked['season'][index] == index ? "active" : "" } onClick={()=>{handleToggle('season',index,seaSon)}} key={index} >{seaSon}</li>
                                                );
                                            }) : ''}
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                Material
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <ul className="filter_ul_other material-class filter-scroll-class">
                                    {   
                                    Object.keys(dbFilters).length && dbFilters.material ? dbFilters.material.map((meterialItem,index)=>{
                                                return (
                                                    <li className={ Checked['material'].indexOf(index) == -1 ? "" : "active" } key={index} onClick={()=>{handleToggle('material',index,meterialItem)}} >{meterialItem}</li>
                                                );
                                            }) : ''}
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                Characteristics
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <ul className="filter_ul_other filter-scroll-class ">
                                        {   
                                    Object.keys(dbFilters).length && dbFilters.characteristics ? dbFilters.characteristics.map((characteristic,index)=>{
                                                return (
                                                    <li className={ Checked['characteristics'].indexOf(index) == -1 ? "" : "active" } onClick={()=>{handleToggle('characteristics',index,characteristic)}} key={index} >{characteristic}</li>
                                                );
                                            }) : ''}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                Design
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                <ul className="filter_ul_other filter-scroll-class">
                                {   
                                    Object.keys(dbFilters).length && dbFilters.design ? dbFilters.design.map((designItem,index)=>{
                                                return (
                                                    <li className={ Checked['design'].indexOf(index) == -1 ? "" : "active" } onClick={()=>{handleToggle('design',index)}} key={index,designItem} >{designItem}</li>
                                                );
                                            }) : ''}
                                </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                Color
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    <ul className="filter_ul_other filter-scroll-class">
                                    {   
                                    Object.keys(dbFilters).length && dbFilters.color ? dbFilters.color.map((colorItem,index)=>{
                                                return (
                                                    <li className={ Checked['color'].indexOf(index) == -1 ? "" : "active" } onClick={()=>{handleToggle('color',index,colorItem)}} key={index} >{colorItem}</li>
                                                );
                                            }) : ''}
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                Weight
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    {/* <Range min={0} max={500} step={10} value={weightRange} onChange={(value)=>{setweightRange(value)}}/> */}
                                    <Range min={0} max={1000} step={10} aria-valuetext="GSM" ariaLabelForHandle='0 GSM' ariaLabelledByForHandle="100 GSM" value={weightRange} onChange={(value)=>{setweightRange(value)}}  onAfterChange={(value)=>{handleToggle('weight',value)}}/>
                                    <p className="range_p"><span>{weightRange[0]} GSM </span><span>{weightRange[1]} GSM</span></p>

                                    
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                Width
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="8">
                                <Card.Body>
                                    <Range min={0} max={200} step={10} value={widthRange} onChange={(value)=>{setWidthRange(value)}} onAfterChange={(value)=>{handleToggle('width',value)}}/>
                                    {/* <p className="range_p">{widthRange[1]} CM</p> */}
                                    <p className="range_p"><span>{widthRange[0]} CM </span><span>{widthRange[1]} CM</span></p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        </div>
    )
}
