import React, { useState } from "react";
import ProductDetails from '../products-detail/ProductDetails'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export default function SideBar() {
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
    return (
        <>
       <div style={{ marginTop: "32px" }}>
        <button onClick={() => setState({ isPaneOpenLeft: true })}>
         Close
        </button>
      </div>
      <SlidingPane
        closeIcon={<div>Close</div>}
        isOpen={state.isPaneOpenLeft}
        from="left"
        width="95%"
        onRequestClose={() => {
          setState({ isPaneOpen: false });
        }}
      >
        <div><ProductDetails/></div>
      </SlidingPane>
        </>
    )
}
