import React,{useState,useEffect} from 'react'

//Component Import
import FavList from './FavList'
import FavReq from './FavReq'
import apiClient from '../../api'

export default function Favourites() {
    const [favourites,setFavourites] = useState({});
    const [totalOrders,setTotalRecords] = useState(0);
    const [Skip, setSkip] = useState(0)
    const [Limit, setLimit] = useState(8) 
    const [imagePath,setImagePath] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isFavLoading, setFavLoading] = useState(false);
    const [hasFav,setHasFav] = useState(false)
    useEffect(() => {
        const variables = {
            skip: Skip,
            limit: Limit,
        } 
        getFavourites(variables) 
    },[])
    async function getFavourites(variables){
        setFavLoading(true)
        apiClient.get('api/fav')
            .then(response => {
                if(response.data.status == 'success' && typeof(response.data.fav_list) !='undefined')
                {
                    const dbProducts = response.data.fav_list;
                    setFavourites(dbProducts);
                    setTotalRecords(dbProducts.length);
                    if(dbProducts.length == 0){
                        setFavLoading(false) 
                    }else{
                        setFavLoading(true)
                    }
                    setImagePath(response.data.imagePath)
                    setFavLoading(false)
                    setHasFav(true)
                }else{
                    setFavLoading(false)
                    return;
                }
            });
            
    }
    function handleRemove(id) {
        setFavLoading(true)
        apiClient.delete('api/fav/'+id)
        .then(response => {
            if(response.data.status == "success")
            {
                const newList = favourites.filter((item) => item.id !== id);
                setFavourites(newList);
                setFavLoading(false)
            } 
        }) 
    }  
    return (
        <>
                <div id="products-list-main-wrapper" className="container-fluid fav_scroll">
                    {
                            isLoading ? 
                            <div className="loaderDiv">
                                {/* <img src="assets/img/ajax-loader.gif"></img> */}
                            </div>
                            : ""
                    }
                    <div className="row">
                        <div className="col-md-5 p-0">
                            <FavReq getFavourites={getFavourites} setFavourites={setFavourites} favourites={favourites}  setLoading={setLoading}/> 
                        </div>
                        <div className="col-md-7 p-0">
                            <FavList favourites={favourites}  hasFav={hasFav} isFavLoading={isFavLoading} imagePath={imagePath} handleRemove={handleRemove}/>
                        </div>
                    </div>
                </div> 
        </>
    )
}
