import React from "react"
export default function SelectedFilters(props) {
return (
    <div className="filter_selected_list">
        {
            Object.keys(props.totalFilters).length && Object.keys(props.Filters).length > 0 ?
                Object.keys(props.Filters).map((filter, index) => {
                    props.setGapRequired(false);
                return (
                    props.Filters[filter].map((field,index)=>{
                        var stringName = ""
                        if(filter == "highlight"){
                            stringName = props.totalFilters[filter][field]['title'] ? props.totalFilters[filter][field]['title'] : '';
                            props.setGapRequired(true);
                        }else if(filter == "weight"){
                            var currentWeight = props.Filters[filter][props.Filters[filter].length-1];
                            props.Filters[filter][0][0] = currentWeight[0]
                            props.Filters[filter][0][1] = currentWeight[1]
                            stringName = props.Filters[filter][0]?'weight '+props.Filters[filter][0][0]+'-'+props.Filters[filter][0][1]+' GSM':'';
                            props.setGapRequired(true);
                        }else if(filter == "width"){
                            var currentWidth = props.Filters[filter][props.Filters[filter].length-1];
                            props.Filters[filter][0][0] = currentWidth[0]
                            props.Filters[filter][0][1] = currentWidth[1]

                            stringName = props.Filters[filter][0]?'width '+props.Filters[filter][0][0]+'-'+props.Filters[filter][0][1]+' CM':'';
                            props.setGapRequired(true);
                        }else if(filter == "search_keywords"){
                            stringName = props.Filters[filter][index] ? props.Filters[filter][index] : '';
                            props.setGapRequired(true);
                        }else{
                            stringName = props.totalFilters[filter][field] ? props.totalFilters[filter][field] : '';
                            props.setGapRequired(true);
                        } 
                        return (
                            <div className="selested cursor-pointer" key={index}>
                                <span>{stringName}</span>
                                <span
                                onClick={()=>{ 
                                    const currentIndex = props.Filters[filter].indexOf(field);
                                    const filedData = props.Filters[filter];
                                    filedData.splice(currentIndex, 1)
                                    const newState = { ...props.Filters, [filter]: filedData};
                                    props.setFilters(newState);
                                    props.getProducts(newState);
                                }}
                                ><img src="/assets/img/close_img.png" className="d-inline-block align-middle" alt="close" /></span>
                            </div>
                        )
                    })
                )
                })
            : ""
        }
    </div>
)

}