import React,{useState,useEffect} from 'react'

import { Form,Button,Table } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link, Redirect,withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import '../../scss/signforms.scss'
import apiClient from "../../api"

export default withRouter(function Orders(props) {
    const [ordersInfo, setOrdersinfo] = useState({});
    const [imagePth, setImagepath] = useState({});
    const [paginationVal, setPaginationVal] = useState({
        start: 0,
        limit: process.env.REACT_APP_ORDERS_PAGINATION_LIMIT
    })
    const[totalOrders, setTotalorders] = useState();
    const pageCount =  Math.ceil(totalOrders / paginationVal.limit)
    const [orderProducts, setOrderProducts] = useState({})
    const [pageNumber, setpageNumber] = useState(0)


    useEffect(() => {
        getOrders()
    }, [])
    function getOrders(){
        props.setLoading(true)
        apiClient.get('api/orders',{params: {
            start : paginationVal.start,
            limit: paginationVal.limit
        } })
            .then(response => {
                if(response.data.status == 200)
                {
                    setOrdersinfo(response.data.order_list)
                    setpageNumber(paginationVal.start)
                    setImagepath(response.data.imagePath)
                    setTotalorders(response.data.total_count)
                }
                props.setLoading(false)
            })
    }
    function handlePageClick(data) {
        let selected = data.selected;
        let offset = Math.ceil(selected * paginationVal.limit);
        paginationVal.start = offset;
        getOrders()
    };
    return (
        <>
            <div className="signin">
                <div className="orders_box">
                    <p className="signin_p">My Orders</p>
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Order Date </th>
                                <th>Status</th>
                                <th>View / Print</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                
                                Object.keys(ordersInfo).length > 0 ?
                                ordersInfo.map((order, index) =>
                                    <tr key={index}>
                                        <td>{((pageNumber)+(++index))}</td>
                                        <td><Moment format="DD.MM.YYYY">
                                                { new Date(order.order_date) }
                                            </Moment></td>
                                        <td>{order.status == 'Order' ? 'Order Placed' : 'Order Delivered'}</td>
                                        <td>
                                            <ul>
                                                <li><a href={"/ordersview/view/"+order.order_id} target="_blank"><img src="/assets/img/view.png" className="d-inline-block align-middle" alt="view" /></a></li>
                                                <li><a href={"/ordersview/pdf/"+order.order_id} target="_blank"><img src="/assets/img/print.png" className="d-inline-block align-middle" alt="print" /></a></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    )
                                    : ""
                                }
                        </tbody>
                    </Table>
                    { totalOrders > process.env.REACT_APP_ORDERS_PAGINATION_LIMIT ? 
                    <div className="row">
                        <div className="col-md-12 text-right">
                            {
                                <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={''}
                                pageClassName = {'page-item'}
                                activeLinkClassName={'active'}
                                />
                            }
                        </div>
                    </div>
                    : ""
                   } 

                </div>
            </div>
        </>
    )
})
