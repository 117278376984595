import React from 'react'

import { Form,Button } from 'react-bootstrap';
import {withRouter} from 'react-router-dom'
import '../../scss/signforms.scss'
import MyInformation from './MyInformation'
import ChangePassword from './ChangePassword'

export default withRouter(function Account(props) {
    return (
        <>
            <div className="signin signup">
                <MyInformation  setLoading={props.setLoading} />
                <ChangePassword flashMessages = { props.setFlashMessages } setLoading={props.setLoading} />    
            </div>
        </>
    )
});
