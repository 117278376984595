import React from 'react'

import { Link } from 'react-router-dom';

import '../../scss/footer.scss'

export default function Footer() {
    return (
        <>
          <footer>
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-md-6">
                          <ul className="footer_links">
                              <li>&copy;2020 Workingmenblues.</li>
                              <li><a href="https://workingmenblues.com/terms-conditions/" target="_blank">T&C</a> </li>
                          </ul>
                      </div>
                      <div className="col-md-6">
                          <ul className="social_links text-right">
                              <li><a href={process.env.REACT_APP_FACEBOOK} target="_blank"><img src="/assets/img/facebook.svg" width="10" className="d-inline-block align-top" alt=" " /></a></li>
                              <li><a href={process.env.REACT_APP_INSTAGRAM} target="_blank"><img src="/assets/img/instagram.svg" width="10" className="d-inline-block align-top" alt=" " /></a></li>
                              <li><a href={process.env.REACT_APP_LINKEDIN} target="_blank"><img src="/assets/img/linkedin.svg" width="10" className="d-inline-block align-top" alt=" " /></a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </footer>
        </>
    )
}
