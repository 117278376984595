import React, {useState, useEffect} from 'react'

import { Form,Button } from 'react-bootstrap';

import '../../scss/signforms.scss'

import apiClient from '../../api'; 

import axios from 'axios';
import { useForm } from "react-hook-form";

export default function MyInformation(props) {
    const [userInfo, setUserinfo] = useState({});
    const [ordersList, setOrderslist] = useState([]);
    const [responseStatus, setResponseStatus] = useState([])
    const { register, handleSubmit, watch, errors } = useForm();
    

    useEffect(() => {
        apiClient.get('api/user')
            .then(response => {
                if(response.data.status == 200)
                {
                    setUserinfo(response.data.userInfo)
                }
            })

    },[])

    const onSubmit = data => {
        props.setLoading(true)
        apiClient.put('api/user',data)
            .then(function (response) {
                if (response.data.status == 200) {
                    setResponseStatus({ type: 'alert-success', message: response.data.message });
                    props.setLoading(false)
                } else {
                    setResponseStatus({ type: 'alert-danger', message: response.data.message });
                    props.setLoading(false)
                }
        });
    }

    const handleOnChange = event => {
        const { name, value } = event.target;
        setUserinfo({ ...userInfo, [name]: value });
    };

    return (
        <>
            <div className="signin_form my_account_div">
                <p className="signin_p">My Information</p> 
                { responseStatus.message && responseStatus.message.length > 0 ? <p className="error-disp new_error_msg">{responseStatus.message}</p> : "" }
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicEmail">
                    <Form.Control type="email" name="email" readOnly placeholder="Email" value={Object.keys(userInfo).length ? userInfo.email:''}  ref={register({ required: true })}/>
                        {errors.email && <span className="error-disp">Email field is required</span>}
                    </Form.Group>
                    <Form.Group controlId="formBasicName">
                    <Form.Control type="text" name="name" placeholder="Name"  onChange={handleOnChange} value={Object.keys(userInfo).length ? userInfo.name:''}  ref={register({ required: true })}/>
                        {errors.name && <span className="error-disp">Name field is required</span>}
                    </Form.Group>
                    <Form.Group controlId="formBasicCompany">
                    <Form.Control type="text" name="company" placeholder="Company"  onChange={handleOnChange} value={Object.keys(userInfo).length ? userInfo.company:''}  ref={register({ required: true })}/>
                        {errors.company && <span className="error-disp">Company field is required</span>}
                    </Form.Group>
                    <Form.Group controlId="formBasicPosition">
                    <Form.Control type="text" name="position" placeholder="Position"  onChange={handleOnChange} value={Object.keys(userInfo).length ? userInfo.position:''}  ref={register({ required: true })}/>
                        {errors.position && <span className="error-disp">Position field is required</span>}
                    </Form.Group>
                    <Button className="login_btn" variant="primary" type="submit">Save Changes</Button>
                </Form>
            </div>
                
        </>
    )
}
