import React,{useState,useContext, useEffect} from 'react'

import { Form,Button,Modal } from 'react-bootstrap';

import { Link,Redirect } from 'react-router-dom';
import OrderPdf from '../orderviews/Orderview'
import '../../scss/signforms.scss' 
import apiClient from '../../api'
import DispatchContext from "../../DispatchContext"
function MyVerticallyCenteredModal(props) {
    const [responseStatus, setResponseStatus] = useState("");

    function handleRequestData()
    {
        props.setLoading(true)
        props.setModalShow(false)
        apiClient.put('api/fav/request')
        .then(function (response) { 
            if(response.data.status == 200)
            { 
                props.setIsRedirect(true)
                        props.getFavourites()
                        props.setFavourites({})
                        props.setLoading(true)
                }else{
                    // setResponseStatus(response.data.message)
                }
        })
    }
     
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="signin_form">
                {
                    <>
                    <p className="signin_p">REQUEST</p>
                    <p>Once your request is in, we will contact you with further information. Do you want to request your selection?</p>
                        <span onClick={handleRequestData} className="login_btn login_link" variant="primary">Yes</span>
                    <div className="or">
                        Or
                    </div>
                    <Link to="/" className="forgot_a">Not yet, continue browsing</Link> 
                    </>
                }
                
            </div> 
        </Modal.Body>
      </Modal>
    );
  }

export default function FavReq(properties) {
    
    const [modalShow, setModalShow] = React.useState(false);
    const appDispatch = useContext(DispatchContext)
    const [isRedirct, setIsRedirect] = useState(false)
    return ( 
        <>
        { isRedirct ? <Redirect to="/my-profile" /> : ""}
            <div className="signin signup fav_left">
                <div className="signin_form my_account_div">
                    <p className="signin_p">FAVOURITES</p>
    <p>Request your favourite fabrics. </p>
                    {
                        properties.favourites.length > 0 ?
                        <>
                        <a href={"/printpdf"} className="login_btn login_link" target="_blank">Print</a>
                        <Button className="login_btn" variant="primary"  onClick={() => setModalShow(true)}>Request</Button>
                        </>
                        :
                        <>
                        <a href={"/printpdf"} className="login_btn login_link isDisabled" onClick={ (event) => event.preventDefault() } target="_blank">Print</a>
                        <Button className="login_btn" variant="primary" disabled onClick={() => setModalShow(true)}>Request</Button>
                        </>
                    }
                    
                    
                    <div className="fav_class">
                        <div className="or">
                            Or
                        </div>
                        <a href={"/products"} className="forgot_a">Continue browsing</a>
                    </div>
                </div> 
                <MyVerticallyCenteredModal setIsRedirect={setIsRedirect} setLoading={properties.setLoading} show={modalShow} onHide={() => setModalShow(false)} setModalShow={setModalShow} getFavourites={properties.getFavourites} setFavourites={properties.setFavourites}/>            
            </div>
        </>
    )
}
