import React,{useState,useEffect} from 'react'
import apiClient from "../../api"
import Moment from 'react-moment';
import '../../scss/print.scss';
import {
  useParams
} from "react-router-dom";

export default function Orderview(){
    const [orderfavourites, setorderfavourites] = useState({})
    const [userInfo, setUserinfo] = useState({})
    const [pageCount, setPageCount] = useState(0)
    var proCount = 0;
    var { orderId } = useParams();
    var { viewType } = useParams();
    useEffect(() => {
      getFavourites()
     
    }, [])
    async function getFavourites(){

      apiClient.get('api/orders/'+orderId).then(response =>{
          if(response.data.status == 200){
            setorderfavourites(response.data.order_list.orders)
            setPageCount(Math.ceil(response.data.order_list.orders.length/8))
            if(viewType !== 'view')
            { 
              printPage()
            }
          }
      })
      apiClient.get('api/user').then(responseuser => {
        setUserinfo(responseuser.data.userInfo)
      })
     }

     function printPage(){
    //  window.print();
       setTimeout(function(){ 
        window.print();
       },500)
     }
     return (
      <>
  
      {
         Object.keys(orderfavourites).length > 0 ?
         orderfavourites.reduce((m, k, i) => {
         if (i % 8 === 0) {
           m.push([k])
         } else {
           m[m.length - 1].push(k)
         }
         return m
       }, []).map((grouped, index) => (
        <div style={{margin: 'margin: 0px 0px', padding:'0 !important'}}>
        <page size='A4'>
        <div className="page-container Section1" style={{maxWidth: '1240px',width: '820px', margin: '0px auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 8px'}}>
           
            <table width="100%" className="main-table-wrapper pagebreak" cellSpacing={0} cellPadding={0}>
             <tbody className="pagebreak">
                <tr>
                  <table align="center" role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%" style={{margin: 'auto'}}>
                    <tbody>
                      <tr></tr>
                    </tbody>
                  </table>
                  <table  width="100%" cellSpacing={0} cellPadding={0}>
                      <tbody>
                        <tr>
                          <td valign="top" width="100%">
                            <div style={{boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px'}}>
                              <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                <tbody>
                                  <tr className="bg_white" style={{background: '#fff'}}>
                                    <td width="60%" className="logo" style={{padding: '1.9em 1.5em', textAlign: 'left'}}>
                                      <h1><a href="/"> <img src="/assets/img/logo.svg" alt="" style={{width: '100%', maxWidth: '300px', height: 'auto', display: 'block', marginLeft: '25px'}} /></a>
                                      </h1> </td>
                                    <td width="40%" style={{textAlign: 'right', paddingRight: '10px'}}>
                                      <ul className="social_links">
                                        <li style={{display: 'inline-block', margin: '0 12px', lineHeight: '0px', boxShadow: '0px 0px 5px #fff'}}>
                                          <a href="https://www.facebook.com/" target="_blank"><img src="/assets/img/facebook.jpg" width={12} className="d-inline-block align-top" alt=" " /></a>
                                        </li>
                                        <li style={{display: 'inline-block', margin: '0 12px', lineHeight: '0px', boxShadow: '0px 0px 5px #fff'}}>
                                          <a href="https://www.instagram.com/" target="_blank"><img src="/assets/img/instagram.jpg" width={12} className="d-inline-block align-top" alt=" " /></a>
                                        </li>
                                        <li style={{display: 'inline-block', margin: '0 12px', lineHeight: '0px', boxShadow: '0px 0px 5px #fff'}}>
                                          <a href="https://www.linkedin.com/" target="_blank"><img src="/assets/img/linkedin.jpg" width={12} className="d-inline-block align-top" alt=" " /></a>
                                        </li>
                                      </ul></td>
                                  </tr>
                                </tbody></table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table> ​
                </tr>
                <tr>
                  <table role="presentation" className="inner-tbl-content table_height" cellSpacing={0} cellPadding={0} border={0} width="100%" style={{background:'#f6f6f6'}} >
                    <tbody>
                      <tr>
                        <td style={{verticalAlign: 'top', width: '30%',paddingLeft: '30px'}}>
                          <p style={{marginTop: '78px', marginBottom: '8px', fontSize: '13px'}}>FAVOURITES</p>
                          <hr style={{borderBottom: '1px solid #a1a1a1', margin:'8px 0px'}} />
                          <p style={{textAlign: 'center', fontSize: '12px', marginTop: '35px'}}>{userInfo.name}</p>
                          <p style={{textAlign: 'center', fontSize: '12px', margin: '20px 0px'}}>{userInfo.position}</p>
                          <p style={{textAlign: 'center', fontSize: '12px', marginBottom: '35px'}}>{userInfo.company}</p>
                          <hr style={{borderBottom: '1px solid #a1a1a1', margin:'8px 0px'}} />
                          <p style={{textAlign: 'right', fontSize: '12px', margin: '10px 0px'}}><Moment format="DD/MMMM/YYYY">
                                                  { new Date() }
                                              </Moment></p>
                        </td>
                        <td style={{width: '4%'}}>
                        </td>
                        <td style={{verticalAlign: 'top', width: '60%'}}>
                          
                          <div className="fav_right" style={{width: '100%',paddingTop:'70px',paddingBottom:'20px' ,maxWidth: '720px',paddingRight: '15px', marginTop: '0px',boxShadow: 'none'}}>
                            <div className="favlist" style={{marginRight: '14px',marginTop: '-31px'}}>
                        {
                          grouped.map((products, j) =>
  
                              <div className="listitem" key={products.id} style={{ marginLeft:'0px',background: '#fbfbfb', position: 'relative', marginBottom: '20px', width: '100%', height: '112px', borderBottom:'1px solid #e6e6e6',borderRight:'1px solid #e6e6e6',boxShadow:'none'}}>
                                <div className="tb-row" style={{display: 'table-row'}}>
                                  <div className="tb-cell" style={{display: 'table-cell', verticalAlign: 'top'}}>
                                    <img src={process.env.REACT_APP_PRO_MODE+"/thumb/"+products.zoomed_image} className="d-inline-block align-middle" alt="product" style={{width: '112px', height: '112px', objectFit: 'cover'}} />
                                  </div>
                                  <div className="tb-cell pd-left" style={{paddingLeft: '12px'}}>
                                    <div className="product_info" style={{padding: '5px'}}>
                                      <p className="title" style={{margin: '7.px 0px 0px'}}><span style={{color: '#000', textDecoration: 'none', fontSize: '11px'}}>{products.product_code}</span></p>
                                      <p className="product_no" style={{color: 'grey', fontSize: '9.5px', marginBottom: '10px', marginTop:'8px'}}>Beading / Embellishment, Embossing, Guipure / Chemical Lace</p>
                                      <ul className="list_ul" style={{margin: '0px', padding: '0px', lineHeight: '14px'}}>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>{products.season}</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '10px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>{products.composition}</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}> Abstract</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>{products.color}</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>{products.weight} GSM</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>{products.width} CM</li>
                                        {
                                                              products.highlight_wmb ? 
                                                              <>
                                                              <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>WMB Patented</li>
                                                              </>
                                                          : ""
                                                          }
                                                          {
                                                              products.highlight_new ? 
                                                              <>
                                                              <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>New</li>
                                                              </>
                                                          : ""
                                                          }
                                                          {
                                                              products.highlight_eco ? 
                                                              <>
                                                              <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>|</li>
                                        <li style={{listStyle: 'none', display: 'inline-block', fontSize: '9.5px', color: '#000', margin: '0 1px'}}>Ecological</li>
                                                              </>
                                                          : ""
                                                          }
                                        
                                        
                                        
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                            </div>
                          </div>
                        
                      </td>
                      </tr>
                        
                    </tbody>
                  </table>
                  </tr>
                </tbody>
  
                <tfoot className="pagebreak" style={{background:'#fff'}}>
                  <tr>
                    <td style={{textAlign: 'center', padding: '20px 0px'}}>
                      <p style={{margin: '0px', color: '#2d2d2d', fontSize: '10px', lineHeight: '20px', letterSpacing: '.2px'}}> bonjour@workingmenblues.com
                        <br />www.workingmenblues.com</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{textAlign: 'right', paddingRight: '10px',position:'relative',top:'-18px'}}>
                      {/* <p style={{margin: '0px', color: '#2d2d2d', fontSize: '10px', lineHeight: '5px', letterSpacing: '.2px'}}></p> */}
                      <p className="page-footer-page-num" style={{margin: '0px', color: '#2d2d2d', fontSize: '10px', lineHeight: '0px', letterSpacing: '.2px'}}>{index+1} of {pageCount} pages</p>
                    </td>
                  </tr>
                </tfoot>
              </table>
         </div>
         </page>
         </div>
        ))
        : ""
      }
          
      </> 
    )
  }