import React,{useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Figure } from 'react-bootstrap';
import '../../scss/product-details.scss';
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
  } from "react-image-magnifiers";

import '../../scss/products.scss'
import '../../scss/print.scss'
import { Link as ScrollLink,Element,animateScroll as scroller } from 'react-scroll';

export default function ProductDetails(properties) {
    // const [productInfo, setProductInfo] = useState({})
    // const [responseStatus, setResponseStatus] = useState([])
    const [isLoading, setLoading] = useState(true);
    const [showScrollImg, setShowScrollImg] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);
    const [rerender, setRerender] = useState(); // or any state
    const [afterRender, setAfterRender] = useState();// internal state
    const [activeImg, setActiveimage] = useState(process.env.REACT_APP_WEBSITE_URL+"admin/public/uploads/"+properties.productInfo.zoomed_image)
    const [iframeUrl, setIframeUrl] = useState("")
    
   
    useEffect(() => {
        if (!afterRender) return;
     }, [afterRender]);
     
     useEffect(() => {
        setAfterRender(true); 
     }, [rerender]); 
     


    function addFavourite(productID,favID)
    {
        properties.handleFavform(productID,favID)
        // properties.setModalShow(true)
    }
    function removeFavourite(productID)
    {
        properties.handleFavRemove(productID)
        // properties.setModalShow(true) 
    }
    function handleIframe(e) {
        e.preventDefault();
        handlePrint()
        return;
        //alert(properties.productInfo.id)
        console.log(process.env.REACT_APP_WEBSITE_URL+"product/"+properties.productInfo.id)
        setIframeUrl(process.env.REACT_APP_WEBSITE_URL+"product/"+properties.productInfo.id)
    }
    function handleImageLoad(){
        setLoading(false)
    }

    function convertToStringCase(productString)
    {
        if(typeof productString === 'object' && productString !== null){
            var productCharastics = [];
            for (var [key, value] of Object.entries(productString)) {
                value = value.replace('\/','/ ');
                value =  value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                value = value.replace('\/ ','/');
                productCharastics[key] = value;
            }
        }else{
            var productCharastics = "";
            productCharastics =  productString.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        }
        return productCharastics;
    }

    const handleProductDetailScroll = (event) => {
        if (event.target.scrollTop > 50) {
            setShowScrollImg(true)
        } else {
            setShowScrollImg(false)
        }
    }
    useEffect(() => {
        if (isPrinting) {
            setTimeout(function () {
                setIsPrinting(false)
            }, 7500)
        }
    }, [isPrinting]);
    function handlePrint()
    {
        setIsPrinting(true)
        window.print()
    }

    return (
        <>  
            {/* <Header/> */}
            
            <div className="overlay">
                <section id="containerElement" onScroll={(e)=>{handleProductDetailScroll(e)}} name="scrollableMain" className="products_sec product_details_sec">
                    <Element name="firstInsideContainer" className="container-fluid">
                        <div className="row" id="product-details-content">

                            <div className="product_info_middle mt-50">
                                <div className="imgthumb">
                                    <Figure>
                                        <Figure.Image alt="Product" src={properties.productInfo.zoom_image} onClick={(e) => setActiveimage(process.env.REACT_APP_WEBSITE_URL+"admin/public/uploads/"+properties.productInfo.zoomed_image)}/>
                                    </Figure>
                                    <Figure>
                                        <Figure.Image alt="Product" src={properties.productInfo.image} onClick={(e) => setActiveimage(process.env.REACT_APP_WEBSITE_URL+"admin/public/uploads/"+properties.productInfo.product_image)}/>
                                    </Figure>
                                    
                                </div>
                                <div className="zoomimg">
                                
                                {/* {
                                    isLoading ? 
                                    <div className="loaderDiv">
                                        <img src="assets/img/ajax-loader.gif"></img>
                                    </div>
                                    : ""
                                }  */}
                                { activeImg && afterRender ?  <SideBySideMagnifier 
                                    imageSrc={activeImg} className="actual_img"
                                    imageAlt="Zoom Image"
                                    largeImageSrc={activeImg} // Optional
                                    magnifierSize="50"
                                    onLargeImageLoad={handleImageLoad}
                                    /> : ""
                                }
                                
                                </div>
                            </div>
                            <div className="product_info_right mt-50">
                                <div className="product_div">
                                    <ul className="product_detail_ul">
                                        <li>Article: {properties.productInfo.product_code}</li>
                                        <iframe src={iframeUrl} style={{position:'absolute',top:'-1000px'}}></iframe>
                                        <li className="right_info"><a className="print_label text-dark font-weight-bold" href="#" onClick={handleIframe}>{isPrinting ? 'Please wait...' : 'Print'}</a></li>
                                        {/* <li className="right_info"><u><Link to={"/product/"+properties.productInfo.id} >Print</Link></u></li> */}
                                    </ul> 
                                    <div className="space-50"></div>

                                    <table style={{width:'100%'}} >
                                <tbody>
                                    <tr className="product_detail">
                                        <td>Season</td>
                                        <td className="right_info">{ properties.productInfo.season }</td>
                                    </tr>
                                    <tr className="product_detail">
                                        <td align="top">Composition</td>
                                        <td className="right_info line_height">{ convertToStringCase(properties.productInfo.composition) }</td>
                                    </tr>
                                    <tr className="product_detail">
                                        <td>Characteristics</td>
                                        <td className="right_info line_height">{ Object.values(convertToStringCase(properties.productInfo.characteristics)).join(',') }</td>
                                        {/* <td className="right_info line_height">{ Object.values(convertToStringCase(properties.productInfo.characteristics)).join(',') }</td> */}
                                        {/* <td className="right_info line_height">{ Object.values(properties.productInfo.characteristics).join(',') }</td> */}

                                        
                                    </tr>
                                    <tr className="product_detail">
                                        <td>Design</td>
                                        <td className="right_info">{ Object.values(convertToStringCase(properties.productInfo.design)).join(',') }</td>
                                    </tr>
                                    <tr className="product_detail">
                                        <td>Color</td>
                                        <td className="right_info">{ Object.values(convertToStringCase(properties.productInfo.color)).join(',') }</td>
                                    </tr>
                                    <tr className="product_detail">
                                        <td>Weight</td>
                                        <td className="right_info">{ properties.productInfo.weight } GSM</td>
                                    </tr>
                                    <tr className="product_detail">
                                        <td>Width</td>
                                        <td className="right_info">{ properties.productInfo.width } CM</td>
                                    </tr>
                                    { 
                                        properties.productInfo.highlight_wmb ?
                                        <tr className="product_detail">
                                            <td style={{width:'40%'}}>WMB Patented design</td>
                                            <td className="right_info">Yes</td>
                                        </tr>
                                        : ""
                                    }
                                    { properties.productInfo.highlight_eco ?
                                        <tr className="product_detail">
                                            <td>Ecological</td>
                                            <td className="right_info">Yes</td>
                                        </tr>
                                        : ""
                                    }
                                    { properties.productInfo.highlight_new ?
                                        <tr className="product_detail">
                                            <td>New</td>
                                            <td className="right_info">Yes</td>
                                        </tr>
                                        : ""
                                    }
                                    </tbody>
                                    </table>
                                    <div className="fav_next">
                                        {
                                            properties.productInfo.favorite_id == 0 || properties.productInfo.favorite_id == "unfavourite" ?
                                                <div className="left cursor-pointer" onClick={()=>{addFavourite(properties.productInfo.id,properties.productInfo.favorite_id)}}>
                                                    <p>Add to my Favourites</p>
                                                    <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                </div>
                                            : 
                                                <div className="left cursor-pointer" onClick={()=>{removeFavourite(properties.productInfo.id)}}>
                                                    <p>Remove from Favourites</p>
                                                    <span className="favorite"><img src="/assets/img/heart-fill.png" className="d-inline-block align-middle" alt="heart" /></span>
                                                </div>
                                        }
                                        <div className="right " >
                                            <Link to={"/favourites"}> <p>Go to Favourites</p>
                                                <span className="favorite"><img src="/assets/img/arrow.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Element>
            </section>
            </div>
            {/* <Footer/> */}
            {showScrollImg ? <ScrollLink activeClass="active" to="firstInsideContainer" spy={true} smooth={true} duration={250} containerId="containerElement">
                <div className="scrollTopIcon mobile-sroll-up">
                    <img src="/assets/img/arrow-up.svg" width={20} />
                </div>
            </ScrollLink> : ''}
        </>
    )
}
