import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Button, Card, Accordion, Figure, Modal } from "react-bootstrap";
import { Range } from "rc-slider";
import ProductDetails from "../products-detail/ProductDetails";
import DispatchContext from "../../DispatchContext";
import StateContext from "../../StateContext";

import $ from "jquery";

import apiClient from "../../api";
import ProductFilters from "./sections/ProductFilters";
import SelectedFilters from "./sections/SelectedFilters";
import { animateScroll as scroll } from 'react-scroll'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../../scss/products.scss";
import "rc-slider/assets/index.css";
import { Redirect,useParams,useLocation,useHistory } from "react-router-dom";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal className="fixed-right product_model" {...props}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <ProductDetails
          handleFavRemove={props.handleFavRemove}
          handleFavform={props.handleFavform}
          setModalShow={props.setModalShow}
          productInfo={props.productInfo}
        />
      </Modal.Body>
    </Modal>
  );
}


export default function Products(props) {
  const isSearchPage  = props.isSearch;
  const isDetailPage  = props.isDetailPage;
  const isProductDetailPage  = props.isProductDetail;
  let history = useHistory()
  let location = useLocation()
  const [Autovalue, setAutoValue] = useState("");
  const btnRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [hideProductsList, setHideProductsList] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const initTimeRange = [0, 50];
  const [timeRange, setTimeRange] = useState(initTimeRange);
  const initWidthRange = [0, 70];
  const [widthRange, setWidthRange] = useState(initWidthRange);

  const [isLoading, setLoading] = useState(false);
  const [productInfo, setProductInfo] = useState({});
  const [totalFilters, setTotalFilters] = useState([]);
  const [filterData, setFilterData] = useState({});
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const [Products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalSearchProducts, setTotalSearchProducts] = useState(0);

  const [responseStatus, setResponseStatus] = useState([]);
  const [Skip, setSkip] = useState(0);
  const [seachVal, setSeachVal] = useState("");
  const [Limit, setLimit] = useState(20);
  const [responseStatusBydefault, setresponseStatusBydefault] = useState(0);

  const [isProductLoaded, setIsProductLoaded] = useState(false);

  const [loadStatusCheck, setLoadStatusCheck] = useState(true);
  const inputRef = useRef(null);
  const autoSuggestRef = useRef(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "Search             "
  );
  const [autoSuggestItemClass, setAutoSuggestItemClass] = useState();
  const [autoSuggestClass, setAutoSuggestClass] = useState("d-none");
  const [autoSuggestCount, setAutoSuggestCount] = useState(0);

  const [filtersMultiValues, setFiltersMultiValues] = useState([]);

  const[gapRequired, setGapRequired] = useState(false);


  function AutoList(props) {
    var items = props.items;
    var autoSuggestCount = props.autoSuggestCount;
    var searchKeywordInput = props.searchKeywordInput;
    var searchKeywordInputClick = props.searchKeywordInputClick;
  
    if (items && items.length) {
      return (
        <>
          {items.map((item, i) =>
            i < 115 ? (
              <li
                key={i}
                className={
                  i == autoSuggestCount - 1
                    ? "auto-suggest-item-selected"
                    : "auto-suggest-item"
                }
                onClick={(e) => searchKeywordInputClick(item.name)}
              >
                {item.name.toLowerCase()}
              </li>
            ) : (
              ""
            )
          )}
        </>
      );
    } else {
      setAutoSuggestClass("d-none");
      return (<></>);
    }
  }

  useEffect(() => {
    if (searchPlaceholder == '') {
      setAutoSuggestClass('d-none')
    }  
  },[searchPlaceholder])

  function onClickOutSide(e) {
    if (autoSuggestClass == "d-none") {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setSearchPlaceholder("Search             ");
      }
      if (
        autoSuggestRef.current &&
        !autoSuggestRef.current.contains(e.target)
      ) {
        setAutoSuggestClass("d-none");
      }
    }
  }
  const [Filters, setFilters] = useState({
    heighlight: [],
    season: [],
    material: [],
    characteristics: [],
    design: [],
    color: [],
    composition: [],
    weight: [],
    width: [],
    search_keywords: [],
  });

  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
    isPaneOpenNew: false,
    isPaneOpenLeftNew: false,
  });
  const [itemsC, setItems] = useState([]);
  const [items, setItemsAutoFil] = useState([]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", onClickOutSide);
    window.addEventListener('hashchange', handleBackBtn);
    return () => {
      window.removeEventListener('hashchange', handleBackBtn);
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", onClickOutSide);
    };
  }, []);
  useEffect(() => {
    setItems(filtersMultiValues);
  });

  useEffect(() => {
    if (isDetailPage && location.state && location.state.product) {
      showProductDetails(location.state.product);
    }
    if (location.pathname == '/products') {
      setHideProductsList(false);
      setState({ isPaneOpenLeft: false });
      setState({ isPaneOpenLeftNew: false });
    } else if (location.pathname == '/products/search') {
      setHideProductsList(true);
      setState({ isPaneOpenLeftNew: true });
      setShowSearchBox(true)
    }
  }, [location.pathname])


  const isSmallDevice = () => {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 768
  }

  function autoFilters(str) {
    var newFilData = [];
    if (str) {
      filtersMultiValues.filter((e) => {
        if (typeof(e.name) == 'string') {
        if (e.name.toUpperCase().startsWith(str.toUpperCase())) {
            newFilData.push({ name: e.name })           
          }
        } else {
          // console.log(typeof(e.name));
        }
        
      }
      );
      

      // newFilData = filtersMultiValues.filter((e) =>
        // e.name.toUpperCase().includes(str.toUpperCase())
      // );

      apiClient.get("api/product/"+str).then((response) => {
        if (response.data.status == "success") {
          if (response.data.product_list.length > 0) {
            response.data.product_list.map((product) =>
              newFilData.push({ name: product.product_code })
            );
          }
        }
        setItemsAutoFil(newFilData.slice(0, 5));
        setAutoSuggestClass("");  
      });
      
    } else { 
    // hide auto suggest here
    setAutoSuggestClass("d-none");
    }
  }
  function similarProducts() {
    apiClient.get("api/product/WMB-243-ESW-").then((response) => {
      if (response.data.status == "success") {
        response.data.product_list.map((product) =>
          filtersMultiValues.push({ name: product.product_code })
        );
      }
    });
  }
  useEffect(() => {
    if (!isFetching || Skip==0) return;
    autoLoadProducts();
  }, [isFetching]);
  useEffect(() => {
    loadFilteredProducts(Filters);
  }, [Filters]);

  function handleScroll() {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
  
    if (scrollTop < 100) {
      setShowScrollToTop(false);
    } else {
      setShowScrollToTop(true);
    }
    if (
      window.innerHeight + scrollTop + 1 <
        document.documentElement.offsetHeight ||
      isFetching ||
      hasMoreRecords == false )
      return;
    setIsFetching(true);
  }

  function handleBackBtn(event) {
    
    event.preventDefault();
    event.returnValue = '';
    return "noo"
  }

  function loadFilteredProducts(filters) {
    setSkip(0);
    getProducts(filters, -1);
  }
  async function getProducts(variables, start = 0) {
    if (!loadStatusCheck) {
      return false;
    } else {
      setLoadStatusCheck(false);
    }
    setLoading(true);
    var startFrom = start ? start : Skip;
    if (start == -1) {
      startFrom = 0;
    }
    apiClient
      .post("api/products", { variables, start: startFrom, limit: Limit })
      .then((response) => {
        if (
          response.status == 200 &&
          typeof response.data.product_listing != "undefined"
        ) {
          const dbProducts = response.data.product_listing;
          setTotalProducts(response.data.total_records);
          setTotalSearchProducts(response.data.search_records_cnt);
          if (startFrom == 0) {
            setProducts(dbProducts);
          } else {
            const testProducts = Products.concat(dbProducts);
            setProducts((Products) => [...Products.concat(dbProducts)]);
          }
          setLoading(false);
          setIsFetching(false);
          setIsProductLoaded(true);

          setLoadStatusCheck(true);
        } else {
          setLoading(false);
          alert("Products could not be fetched");
          return;
        }
      });
  }

  async function handleFavform(product_id, favStatus) {
    apiClient
      .post("api/fav", {
        product_id: product_id,
        favStatus: favStatus,
      })
      .then((response) => {
        if (response.data.status == "success") {
          const updateProductsList = Products.filter((item) => {
            if (item.id == product_id) {
              item.favorite_id = "favourite";
            }
          });
          setResponseStatus({
            type: "alert-success",
            message: response.data.msg,
          });
        } else {
          setResponseStatus({
            type: "alert-danger",
            message: response.data.msg,
          });
        }
      });
  }
  async function handleFavRemove(product_id, fav_id) {
    apiClient.delete("api/fav/" + product_id).then((response) => {
      if (response.data.status == "success") {
        const updateProductsList = Products.filter((item) => {
          if (item.id == product_id) {
            item.favorite_id = "unfavourite";
          }
        });
        setResponseStatus({
          type: "alert-success",
          message: response.data.msg,
        });
      } else {
        setResponseStatus({ type: "alert-danger", message: response.data.msg });
      }
    });
  }

  function autoLoadProducts() {
    const startFrom = Skip + 20;
    const hasmore = startFrom >= parseInt(totalProducts) ? false : true;
    setHasMoreRecords(hasmore);
    if (!hasMoreRecords || !hasmore) {
      setIsFetching(false);
      return;
    }

    setSkip(startFrom);
    getProducts(Filters, startFrom);
  }

  function showProductDetails(productData) {
    if (location.pathname == '/product/details') {
      setHideProductsList(true)
      setProductInfo(productData);
      setState({ isPaneOpenLeft: true });
    } else {
      history.push({
        pathname: '/product/details',
        state: { product: productData },
      });
    }
  }
  function searchFormSubmit(e) {
    e.preventDefault();
    if (autoSuggestCount) {
    } else {
      if (seachVal && seachVal.length > 0) {
        Filters.search_keywords[0] = seachVal;
        getProducts(Filters);
        document.getElementById("searchField").value = "";
      }
    }
  }
  function searchKeywordInputClick(inputVal) {
    setSeachVal("");
    setSearchPlaceholder("Search             ");
    if (inputVal && inputVal.length > 0) {
      Filters.search_keywords[0] = inputVal;
      getProducts(Filters);
      document.getElementById("searchField").value = "";
    }
    history.push('/products');
    setAutoSuggestClass("d-none");
  }
  function searchKeywordInput(inputVal) {
    setSeachVal(inputVal);
    setSearchPlaceholder("Search             ");
    /*if (inputVal && inputVal.length > 0) {
      Filters.search_keywords[0] = inputVal;
      getProducts(Filters);
      document.getElementById("searchField").value = "";
    }*/
    setAutoSuggestClass("d-none");
  }
  function keypressHandler(e) {

    if (e.key === "Enter") {
      if (autoSuggestCount) {
        searchKeywordInputClick(items[autoSuggestCount - 1].name)
      } else {
      }
      history.push('/products');
      setAutoSuggestClass("d-none");
      inputRef.current.blur();
      setSearchPlaceholder("Search             ");
      setSeachVal("");
    }

    if (e.key === "ArrowUp") {
      if (autoSuggestCount - 1 > 0) {
        var updatedCount = autoSuggestCount - 1;
        setAutoSuggestCount(updatedCount);
      }
      
    }

    if (e.key === "ArrowDown") {
      if (autoSuggestCount < items.length) {
        var updatedCount = autoSuggestCount + 1;
        setAutoSuggestCount(updatedCount);
      }
    }
  }

  useEffect(() => {
    if (state.isPaneOpenLeftNew === true || location.pathname=='/products/search') {
        setShowSearchBox(true)
    } else {
      setShowSearchBox(false)
      }
  },[state.isPaneOpenLeftNew])
  

//search textbox onclick
  const handleSearchOnclick = () => {
    setSearchPlaceholder("")
    if (seachVal) {
      
    } else if (isSmallDevice()) {
      setState({ isPaneOpenLeftNew: true });
      setTimeout(() => {
        inputRef.current.focus();
      },200)
    }
  }  
  function searchChangeHandler(e) {
    autoFilters(e.target.value);
    searchKeywordInput(e.target.value);
    if (items.length) {
      setAutoSuggestClass("");
    } else {
      setAutoSuggestClass("d-none");
    }
    if (e.target.value.length<=0) {
      setAutoSuggestClass("d-none");
    }  
  }

  return (
    <>
      <SlidingPane
        className="mobile_tab"
        closeIcon={<div><span className="close_mark">X</span><span className="close_text">Close</span></div>}
        isOpen={state.isPaneOpenLeft}
        from="left"
        width="95%"
        onRequestClose={() => {
          setState({ isPaneOpen: false });
        }}
      >
        <div>
          <ProductDetails
            handleFavRemove={handleFavRemove}
            handleFavform={handleFavform}
            setModalShow={setModalShow}
            productInfo={productInfo}
          />
        </div>
      </SlidingPane>
      <SlidingPane
        className="filtersliding"
        closeIcon={<div><span className="close_mark"><img src="/assets/img/arrow-left.svg" width={17} /></span><span className="close_text">Close</span></div>}
        isOpen={state.isPaneOpenLeftNew}
        from="left"
        width="90%"
        onRequestClose={() => {
          history.push('/products');
          // triggered on "<" on left top click or on outside click
          // setState({ isPaneOpenNew: false });
        }}
      >
        <div>
          <ProductFilters
            filtersMultiValues={filtersMultiValues}
            setSkip={setSkip}
            setTotalFilters={setTotalFilters}
            getProducts={getProducts}
            setFilters={setFilters}
            isSmallDevice={isSmallDevice()}
            Filters={Filters}
            setFilterData={setFilterData}
            totalFilters={totalFilters}
            setFiltersMultiValues={setFiltersMultiValues }
          />
        </div>
      </SlidingPane>

      <section id="products-list-main-wrapper" className={'products_sec'} >
        <div className="container-fluid">
          <div className="row search_sticky">
            <div className="col-md-12">
              <div className="search_box">
                {showSearchBox ?
                  <Form onSubmit={searchFormSubmit}>
                    <Form.Group className="test" controlId="searchField">
                      <div className="test form-group">
                        <Form.Control
                          type="text"
                          className={"hasFocus "+(!searchPlaceholder || seachVal ?'p-0':'')}
                          style={{ borderShadow: "none" }}
                          ref={inputRef}
                          placeholder={searchPlaceholder}
                          autoComplete="off"
                          onKeyUp={(e) => keypressHandler(e)}
                          onClick={(e) => { handleSearchOnclick() }}
                          value={seachVal}
                          onChange={(e) => searchChangeHandler(e)}
                        />
                        <span>
                          {seachVal == "" ? <i></i> : ''}
                        </span>
                    
                        {(totalSearchProducts && totalSearchProducts > 0) ||
                          !isProductLoaded ? (
                            ""
                          ) : (
                            <p>No Records Found</p>
                          )}
                      </div>
                      <div className="searchresults">
                        <div ref={autoSuggestRef} className={autoSuggestClass}>
                          <ul>
                            <AutoList
                              items={items}
                              autoSuggestCount={autoSuggestCount}
                              searchKeywordInput={searchKeywordInput}
                              searchKeywordInputClick={searchKeywordInputClick}
                            />
                          </ul>
                        </div>
                      </div>
                    </Form.Group>
                  </Form>
                  : ''}
                
                <SelectedFilters
                  totalFilters={totalFilters}
                  Filters={Filters}
                  setFilters={setFilters}
                  filterData={filterData}
                  getProducts={getProducts}
                  gapRequired={gapRequired}
                  setGapRequired={setGapRequired}
                />
              </div>
            </div>
          </div>
          <div className={'productlist-main row mt-50 '+(hideProductsList?' d-none':'')}>
            <div className="row">
              <div className="col-md-2 d-none d-sm-none d-md-block" style={{ top: "-16px" }}>
                <ProductFilters
                  Filters={Filters}
                  seachVal={seachVal}
                  setFiltersMultiValues={setFiltersMultiValues}
                  filtersMultiValues={filtersMultiValues}
                  setSkip={setSkip}
                  isSmallDevice={isSmallDevice()}
                  setTotalFilters={setTotalFilters}
                  getProducts={getProducts}
                  setFilters={setFilters}
                  setFilterData={setFilterData}
                  gapRequired={gapRequired}
                />
              </div>
              {/* {
                                isLoading ? 
                                <div className="loaderDiv">
                                    <img src="assets/img/ajax-loader.gif"></img>
                                </div>
                                : ''
                            } */}
              <div className="col-md-9">
                <div className="product_right" style={{ overflow: "hidden" }}>
                  <div className="row">
                    {Object.keys(Products).length
                      ? Products.map((product, index) => {
                          var productDspl =
                            product.composition +
                            " | " +
                            product.weight +
                            " GSM" +
                            " | " +
                            Object.values(product.design).join(", ") +
                            " | " +
                            product.season;
                          if (productDspl.length > 65) {
                            productDspl = productDspl.substring(0, 65) + "...";
                          }
                          var charDspl = Object.values(
                            product.characteristics
                          ).join("|");
                          if (charDspl.length > 30) {
                            charDspl = charDspl.substring(0, 30) + "...";
                          }

                          return (
                            <div
                              className="col-md-3 col-sm-6 col-lg-3"
                              style={{ marginBottom: "1%", paddingLeft: "0px" }}
                              key={product.id}
                            >
                              <div
                                className="product_item_box plr-5-ganes"
                                
                              >
                                <div className="product_box">
                                <div className={"pro-badge-container"}>
                                    {product.highlight_new ? (
                                      <div className={"pro_new_badge"}>NEW</div>
                                    ) : (
                                      ""
                                    )}
                                    {product.highlight_eco ? (
                                      <div
                                        className={"pro_new_badge eco_badge "}
                                      >
                                        ECO
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {product.highlight_wmb ? (
                                      <div
                                        className={
                                          "pro_new_badge eco_badge wmb_badge "
                                        }
                                      >
                                        WMB
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <Figure>
                                    <a
                                      onClick={() =>
                                        showProductDetails(product)
                                      }
                                    >
                                      <Figure.Image
                                        className="normal_img"
                                        alt="Product"
                                        src={product.zoom_image}
                                      />
                                      <Figure.Image
                                        className="zoomed_img"
                                        alt="Product"
                                        src={product.image}
                                      />
                                    </a>
                                    <Figure.Caption>
                                      <span className="pro_id">
                                        {product.product_code}
                                      </span>
                                      <span className="favorite">
                                        {product.favorite_id == 0 ||
                                        product.favorite_id == "unfavourite" ? (
                                          <span
                                            className="cursor-pointer"
                                            onClick={(e) =>
                                              handleFavform(
                                                product.id,
                                                product.favorite_id
                                              )
                                            }
                                            title="Add to Favourites"
                                          >
                                            <img
                                              src="/assets/img/heart.svg"
                                              className="d-inline-block align-middle"
                                              alt="heart"
                                            />
                                          </span>
                                        ) : (
                                          <span
                                            className="cursor-pointer"
                                            onClick={(e) =>
                                              handleFavRemove(
                                                product.id,
                                                product.favorite_id
                                              )
                                            }
                                          >
                                            <img
                                              src="/assets/img/heart-fill.png"
                                              className="d-inline-block align-middle"
                                              alt="heart"
                                            />
                                          </span>
                                        )}
                                      </span>
                                      <p>{charDspl}</p>
                                      <ul className="list_ul">
                                        <li>{productDspl}</li>
                                      </ul>
                                    </Figure.Caption>
                                  </Figure>
                                  
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                    {Skip == 0 && totalProducts > 10 &&
                      <div className="view-more-btn w-100 text-center">
                      <button onClick={() => { setIsFetching(true); autoLoadProducts(); }} className="btn btn-default">VIEW MORE</button>
                      </div>  } 

                    <MyVerticallyCenteredModal
                      handleFavform={handleFavform}
                      handleFavRemove={handleFavRemove}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      setModalShow={setModalShow}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      productInfo={productInfo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        {/* {isFetching===true && !isLoading && <h4 className="text-center"> <img src="assets/img/ajax-loader.gif" /></h4>} */}
      </section>
      {showScrollToTop === true && (
        <div
          onClick={() => {
            scroll.scrollToTop({ smooth: true});
          }}
          className="scrollTopIcon mobile-sroll-up"
        >
          <img src="/assets/img/arrow-up.svg" width={20}/>
        </div>
      )}
    </>
  );
}
