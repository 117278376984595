import React, {useState,useContext} from 'react'

import { Form,Button } from 'react-bootstrap';

import '../../scss/signforms.scss'
import apiClient from '../../api';  
import { useForm } from "react-hook-form";
import DispatchContext from "../../DispatchContext"



export default function ChangePassword(props) {
    const [responseStatus, setResponseStatus] = useState([])
    const [validationErrors, setValidationErrors] = useState({})
    const { register, handleSubmit, setError, errors, watch } = useForm();
    const appDispatch = useContext(DispatchContext)

    const onSubmit = data => {
        props.setLoading(true)
        apiClient.put('api/user/changepwd',data)
            .then(function (response) {
                console.log('response')
                console.log(response)
                if (response.data.status == 200) {
                    apiClient.post('logout').then(logoutResponse=>{
                        appDispatch({ type: "flashMessages", data: response.data.message})
                        appDispatch({ type: "logout" })
                    })
                    props.setLoading(false)
                } else { 
                    setResponseStatus({ type: 'alert-danger', message: response.data.message });
                    setValidationErrors(response.data.message)
                    props.setLoading(false)
                }
        });
    }
    return (
        <>
            <div className="signin_form my_account_div">
                <p className="signin_p">My Account Settings</p>
                { responseStatus.message && responseStatus.message.length > 0 ? <p className="error-disp new_error_msg">{responseStatus.message}</p> : "" }
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="formBasicPassword">
                    <Form.Control type="password" name="current_password" placeholder="Current Password"  ref={register({ required: true })}/>
                        {errors.current_password && <span className="error-disp ">Current Password field is required</span>}
                        {
                            validationErrors.current_password && validationErrors.current_password.length > 0 ?
                            validationErrors.current_password.map((cpError, index) =>
                                <span class="error-disp">{cpError}</span>
                            )
                            :""
                        }
                    </Form.Group>
                    <Form.Group controlId="formBasicNewPassword">
                    <Form.Control type="password" name="new_password" placeholder="New Password"  ref={register({ required: true,minLength: 8 })}/>
                        {errors.new_password && errors.new_password.type === "required" && <span className="error-disp">New Password field is required</span>}
                        {errors.new_password && errors.new_password.type == "minLength" && <span className="error-disp">Minimum 8 characters required.</span>}
                        {
                            validationErrors.new_password && validationErrors.new_password.length > 0 ?
                            validationErrors.new_password.map((npError, index) =>
                                <span class="error-disp">{npError}</span>
                            )
                            :""
                        }
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmNewPassword">
                    <Form.Control type="password" name="password_confirmation" placeholder="Confirm New Password"  ref={register({ required: true,
                    validate: (value) => value === watch('new_password') })}/>
                        {errors.password_confirmation && errors.password_confirmation.type == "required" && <span className="error-disp">Confirm Password field is required</span>}
                        {errors.password_confirmation && errors.password_confirmation.type == "validate" && <span className="error-disp">New Password and Confirm Password should match.</span>}
                        {
                            validationErrors.password_confirmation && validationErrors.password_confirmation.length > 0 ?
                            validationErrors.password_confirmation.map((confirmPassError, index) =>
                                <span class="error-disp password-alert-message">{confirmPassError}</span>
                            )
                            :""
                        }
                    </Form.Group>
                    <div className="space"></div>
                    <Button className="login_btn" variant="primary" type="submit">Change password</Button>
                </Form>
            </div>
        </>
    )
}
