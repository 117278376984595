import { render } from '@testing-library/react';
import React, {useState,useEffect} from 'react'

import { Form,Button,Table } from 'react-bootstrap';

import { Link} from 'react-router-dom';
import apiClient from '../../api'
import '../../scss/signforms.scss' 

export default function FavList(props) {
   return (
            <div className="signin fav_right">

                    {/* {
                         props.isFavLoading ? 
                            <div className="loaderDiv">
                                <img src="assets/img/ajax-loader.gif"></img>
                            </div>
                            : ""
                    } */}
                
                {
                
                
                Object.keys(props.favourites).length ? 
                props.favourites.map((favourite, index) => {
                        return (
                                <div className="favlist" key={favourite.id} >
                                    <div className="listitem">
                                        <div className="tb-row">
                                            <div className="tb-cell">
                                                <img src={props.imagePath+"/thumb/"+favourite.zoomed_image} className="d-inline-block align-middle" alt="product" />
                                            </div>
                                            <div className="tb-cell pd-left">
                                                <div className="product_info">
                                                    {/* <p className="title"><Link to={"/product/"+favourite.id} >{favourite.product_code}</Link></p> */}
                                                    <p className="title">{favourite.product_code}</p>
                                                    <p className="product_no">Beading / Embellishment, Embossing, Guipure / Chemical Lace</p>
                                                    <ul className="list_ul">
                                                        <li>{favourite.season}</li>
                                                        <li>|</li>
                                                        <li>{favourite.composition}</li>
                                                        <li>|</li>
                                                        <li>Abstract</li>
                                                        <li>|</li>
                                                        <li>{favourite.color}</li>
                                                        <li>|</li>
                                                        <li>{favourite.weight} GSM</li>
                                                        <li>|</li>
                                                        <li>{favourite.width} CM</li>
                                                        {
                                                            favourite.highlight_wmb ? 
                                                            <>
                                                            <li>|</li>
                                                            <li>WMB Patented</li>
                                                            </>
                                                        : ""
                                                        }
                                                        {
                                                            favourite.highlight_new ? 
                                                            <>
                                                            <li>|</li>
                                                            <li>New</li>
                                                            </>
                                                        : ""
                                                        }
                                                        {
                                                            favourite.highlight_eco ? 
                                                            <>
                                                            <li>|</li>
                                                            <li>Ecological</li>
                                                            </>
                                                        : "" 
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="close">
                                            <span className="cursor-pointer" onClick={() =>{ if (window.confirm('Are you sure you wish to delete ?')) props.handleRemove(favourite.id)}} >
                                                <img src="/assets/img/close.svg" className="d-inline-block align-middle" alt="close" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                        )
                    })
                   : (!props.hasFav && (Object.keys(props.favourites).length == 0) && props.isFavLoading) ? "" : <>
                       <div className="no-fav">
                           <p className="t-c" >Add the fabrics you love to Favourites and they will be listed here.</p> <p className="t-c" >Start composing your selection of favourites to request your swatches!</p>
                        </div>
                    </>
                   
                }
            </div>
    )
}
