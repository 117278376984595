import React,{ useState,useEffect } from 'react'

import { Form,Button,Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import apiClient from '../../api';
import '../../scss/signforms.scss'
import { Link } from 'react-router-dom';

function MyVerticallyCenteredModal(props) {

    const [showModalStatus, setShowModalStatus] = useState(false)
    function handleClose(){ 
        // console.log('ji')
        // setShowModalStatus(false);
        props.setModalShow(false)
    }
    return (
      <Modal 
        // show={showModalStatus}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            {
                props.responseStatus.message ?
                <div className="signin_form">
                    <p>{props.responseStatus.message}</p>
                </div>

            :
            <div className="signin_form">
                <p className="signin_p">THANK YOU</p>
                <p>Thank you for registering with us! You will receive an email once the admin approves.</p>
                <Form>
                    <span className="login_btn login_link" variant="primary" type="button"  onClick={handleClose}>Close</span>
                </Form>
            </div>

            }
        </Modal.Body>
      </Modal>
    );
  }

export default function Signup() {
    const [isLoading, setLoading] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [responseStatus, setResponseStatus] = useState([])
    const { register, handleSubmit,reset, errors } = useForm();
    const onSubmit = (data,e) => {
        setLoading(true)
        setResponseStatus([])
        apiClient.get('sanctum/csrf-cookie').then(response => {
            apiClient.post('api/register', data)
                .then(function (response) {
                    console.log("hi this is test message")
                    console.log(response)
                    if (response.data.status == 200) {
                        setLoading(false)
                        setModalShow(true)
                        e.target.reset();
                    } else {
                        setModalShow(true)
                        setLoading(false)
                        setResponseStatus({ type: 'alert-danger', message: response.data.msg });
                    }
                });
        });
    }
    return (
        <>
            <div className="signin signup">
                {/* {
                    isLoading ? 
                    <div className="loaderDiv">
                        <img src="assets/img/ajax-loader.gif"></img>
                    </div>
                    : ""
                } */}
                <div className="signin_form">
                    <p className="signin_p">Sign up</p>
                    <p>Are you a brand / designer / buyer?</p>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Email" name="email" ref={register({ required: true })} autoComplete="off" placeholder="Email" />
                            {errors.email && <span className="error-disp">Email field is required</span>}
                        </Form.Group>
                        <Form.Group controlId="formBasicName">
                            <Form.Control type="text" placeholder="Name" name="name" ref={register({ required: true })} autoComplete="off" placeholder="Name" />
                            {errors.name && <span className="error-disp">Name field is required</span>}
                        </Form.Group>
                        <Form.Group controlId="formBasicCompany">
                            <Form.Control type="text" placeholder="Company" name="company" ref={register({ required: true })} autoComplete="off" placeholder="Company" />
                            {errors.company && <span className="error-disp">Company field is required</span>}
                        </Form.Group>
                        <Form.Group controlId="formBasicPosition">
                            <Form.Control type="text" placeholder="Position" name="position" ref={register({ required: true })} autoComplete="off" placeholder="Position" />
                            {errors.position && <span className="error-disp">Position field is required</span>}
                        </Form.Group>
                        <Button className="login_btn" variant="primary" type="submit">Request</Button>
                    </Form>
                    <MyVerticallyCenteredModal show={modalShow} setModalShow={setModalShow} modalShow={modalShow} responseStatus={responseStatus} setResponseStatus={setResponseStatus} onHide={() => setModalShow(false)} />
                </div>
            </div>
        </>
    )
}
