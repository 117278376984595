import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './scss/main.scss'
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    withRouter,
    useParams
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useImmerReducer } from "use-immer"
import { createBrowserHistory } from "history";
import 'bootstrap/dist/css/bootstrap.min.css';
// Context import
import StateContext from "../src/StateContext"
import DispatchContext from "../src/DispatchContext"
//Components import
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './components/home/Home'
import Aboutus from './components/about/Aboutus'
import Products from './components/products/Products'
import ProductsMobile from './components/products/ProductsMobile'
// import ProductDetailsMobile from './components/products/ProductDetailsMobile'
import ProductDetails from './components/products-detail/ProductDetails'
import ProductInfo from './components/products-detail/ProductInfo'
import MyProfile from './components/my-profile/MyProfile'
import Favourites from './components/favourites/Favourites'
import ForgotPassword from './components/forgotpassword/ForgotPassword'
import SideBar from './components/sidebar/SideBar'
import Orderview from './components/orderviews/Orderview'
import Ordersview from './components/ordersviews/Orderview'
import AutoLogin from './components/autologin/AutoLogin'
import apiClient from './api'

import Test from './components/test/Test'

function Main(props) {
    // const [flashMessages, setFlashMessages] = useState("")
    const initialState = {
        loggedIn: Boolean(localStorage.getItem("id")),
        flashMessages: "",
        hidePageContent:false,
        productslist: [],
        orderproductslist: [],
        imagePath: "",
        user: {
            token: localStorage.getItem("id"),
            username: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            company: localStorage.getItem("company"),
            role: localStorage.getItem("role"),
            status: localStorage.getItem("status")
        },
    }

    const [isHeaderFooter, setIsHeaderFooter] = useState(false);
    const [state, dispatch] = useImmerReducer(useReducer, initialState)

    function useReducer(draft, action) {
        switch (action.type) {
            case "login":
                draft.loggedIn = true
                draft.user = action.data
                return
            case "logout":
                draft.loggedIn = false
                return
            case "orderProducts":
                draft.orderproductslist = action.data
                return
            case "flashMessages": 
                draft.flashMessages = action.data
                return
            case "imagePath":
                draft.imagePath = action.data
                return
            case "productslist":
                draft.productslist.push({ product_code: action.value.product_code, composition: action.value.composition, weight: action.value.weight, season: action.value.season })
                return
        }
    }
    //check device is small or not if small change product page view
    const isSmallDevice = () => {
        return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 768
      }

    useEffect(() => {
        if (state.loggedIn) {
            localStorage.setItem("id", state.user.id)
            localStorage.setItem("name", state.user.username)
            localStorage.setItem("email", state.user.email)
            localStorage.setItem("company", state.user.company)
            localStorage.setItem("role", state.user.role)
            localStorage.setItem("status", state.user.status)
        } else {
            localStorage.removeItem("token")
            localStorage.removeItem("id")
            localStorage.removeItem("name")
            localStorage.removeItem("email")
            localStorage.removeItem("company")
            localStorage.removeItem("role")
            localStorage.removeItem("status")
        }
    }, [state.loggedIn])

    const history = createBrowserHistory()
    return ( 
        <StateContext.Provider value = { state }>
        <DispatchContext.Provider value = { dispatch }>
        <React.StrictMode>
        <BrowserRouter> 
                {history.location.pathname.includes('ordersview') || history.location.pathname.includes('printpdf') ? "" : 
                            <Header loggedIn={state.loggedIn} />
                        } 
                <Route path = "/orderpdf" >
                    <Orderview orderfavourites = { state.orderproductslist }/> 
                </Route>

                <Route path = "/test" exact > 
                    <Test />
                </Route>
            <div className="page-wrapper" id="page-main-wrapper">            
            <Switch>
                <Route path = "/printpdf" >
                    <Orderview orderfavourites = { state.orderproductslist }/> 
                </Route>
                <Route path = "/ordersview/:viewType/:orderId" >
                    <Ordersview/> 
                </Route>
''
                <Route path = "/" exact > 
                    {state.loggedIn ? < Redirect to = "/products" /> : <Home /> } 
                </Route> 
                <Route path = "/about-us" > 
                    {!state.loggedIn ? < Redirect to = "/" /> : < Aboutus /> } 
                </Route> 
                <Route path = "/products" exact > 
                    {!state.loggedIn ? < Redirect to = "/" /> : isSmallDevice() ? <ProductsMobile isSearch={false} isProductDetail={false} /> : < Products /> } 
                </Route> 
                <Route path = "/products/search" exact > 
                    < ProductsMobile isSearch={true} />  
                </Route> 
                <Route path = "/products/menu" exact > 
                    < ProductsMobile isMenu={true} />  
                </Route> 
                <Route path = "/product/details" exact > 
                < ProductsMobile isDetailPage={true} />  
                </Route> 
                <Route path = "/my-profile" exact> 
                    {!state.loggedIn ? < Redirect to = "/" /> : < MyProfile /> } 
                </Route > 
                <Route path = "/favourites" >
                    {!state.loggedIn ? < Redirect to = "/" /> : < Favourites /> } 
                </Route> 
                <Route path = "/product-details" > 
                    {!state.loggedIn ? < Redirect to = "/" /> : < ProductDetails /> } 
                </Route> 
                <Route path = "/forgot-password" >
                    <ForgotPassword />
                </Route> 
                <Route path = "/sidebar" >
                    <SideBar />
                </Route>
                <Route path = "/product/:productId" >
                    <ProductInfo />
                </Route>
                <Route path = "/autologin/:autoTokenId" >
                    {/* {!state.loggedIn ? < Redirect to = "/" /> : < AutoLogin loggedIn={state.loggedIn} /> }  */}
                    <AutoLogin />
                </Route>
    
            </Switch> 
            </div>
                    {/* { history.location.pathname.includes('ordersview') || history.location.pathname.includes('printpdf') ? "" : < Footer /> */}
                    { history.location.pathname.includes('ordersview') || history.location.pathname.includes('printpdf') ? "" : < Footer />
                } 
                </BrowserRouter> 
                </React.StrictMode > 
            </DispatchContext.Provider> 
        </StateContext.Provider>
        )
    }
    ReactDOM.render( <
        Main / > ,
        document.getElementById('root')
    );

    serviceWorker.unregister();