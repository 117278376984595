import React from 'react'

import Signin from './Signin'
import Signup from './Signup'
export default function Home(props) {
    return ( 
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 p-0">
                    <Signin flashMessages={props.flashMessages}/>
                </div>
                <div className="col-md-6 p-0">
                    <Signup /> 
                </div>
            </div>
        </div>
        </>
    )
}
