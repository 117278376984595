import React,{useState} from 'react'
import { Form,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../scss/signforms.scss'
import apiClient from "../../api"
import { useForm } from "react-hook-form";
export default function ForgotPassword() {
    const [isLoading, setLoading] = useState(false);
    const { register, handleSubmit, errors, reset } = useForm();
    const [responseStatus, setResponseStatus] = useState([])
    const onSubmit = (data, e) => {
        setLoading(true)
        apiClient.post('api/forgot-password',
                data
        )
            .then(function (response) {
                if (response.data.status == 200) {
                    setLoading(false)
                    setResponseStatus({ type: 'alert-success', message: response.data.message });
                    e.target.reset()

                } else {
                    setLoading(false)
                    setResponseStatus({ type: 'alert-danger', message: response.data.message });
                }
        });
        
    }
    return (
        <>
        <div className="container-fluid">
                {/* {
                    isLoading ? 
                    <div className="loaderDiv">
                        <img src="assets/img/ajax-loader.gif"></img>
                    </div>
                    : ""
                } */}
            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="signin">
                        <div className="signin_form">
                            {responseStatus && 
                                <p className={"alert " + responseStatus.type}> {responseStatus.message} </p>
                            }
                            <p className="signin_p">Forgot Password</p>
                            <p>Please provide your Email ID</p>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" name="email" ref={register({ required: true })} placeholder="Email" />
                                    {errors.email && <span>Email field is required</span>}
                                </Form.Group>
                                <Button className="login_btn login_link" variant="primary" type="submit">Submit</Button>
                                <div className="extra_space"></div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
