import React,{useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Figure } from 'react-bootstrap';
import {
    useParams
  } from "react-router-dom";
import apiClient from '../../api'
import { 
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
  } from "react-image-magnifiers";

  import '../../scss/products.scss'
  
  export default function ProductInfo() {
      const [productInfo, setProductInfo] = useState({})
      const [activeImg, setActiveimage] = useState()
      const [loadCom, setLoadCOm] = useState(false)
      var { productId } = useParams();
      useEffect(() => {
          getProductInfo()
        }, [])
        function getProductInfo(){
            apiClient.get('api/products/'+productId).then(response =>{
                console.log('product details')
                if(response.data.status == 200){
                    setProductInfo(response.data.product_det)
                    setActiveimage(process.env.REACT_APP_PRO_MODE+'uploads/'+response.data.product_det.zoomed_image) 
                        setLoadCOm(true)
                }
            })
        }

      useEffect(() => {
          if (loadCom) {
              window.print();
          }
      }, [loadCom]);
      
        function handlePrint()
        {
            window.print()
        }
    return (
        <>  
            {/* <Header/> */}
            {
            loadCom ?
            <div className="overlay">
                <section className="products_sec product_details_sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="product_info_middle mt-50">
                                <div className="imgthumb">
                                    <Figure>
                                        <Figure.Image alt="Product" src={productInfo.image} onClick={(e) => setActiveimage(productInfo.image)}/>
                                    </Figure>
                                    <Figure>
                                        <Figure.Image alt="Product" src={process.env.REACT_APP_PRO_MODE+'uploads/'+productInfo.product_image} onClick={(e) => setActiveimage(process.env.REACT_APP_PRO_MODE+'/uploads/'+productInfo.product_image)}/>
                                    </Figure>
                                    
                                </div>
                                <div className="zoomimg">
                                {
                                        <SideBySideMagnifier 
                                        imageSrc={activeImg} className="actual_img"
                                        imageAlt="Zoom Image"
                                        largeImageSrc={activeImg} // Optional
                                        magnifierSize="50"
                                        /> 
                                }
                                
                                </div>
                            </div>
                            <div className="product_info_right mt-50">
                                <div className="product_div">
                                    <ul className="product_detail_ul">
                                        <li>Article: {productInfo.product_code}</li>
                                        <li className="right_info"><u><span className="cursor-pointer" onClick={handlePrint}>Print</span></u></li>
                                    </ul>
                                    <div className="space-50"></div>
                                    <ul className="product_detail">
                                        <li>Season</li>
                                        <li className="right_info">{ productInfo.season }</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Composition</li>
                                        <li className="right_info">{ productInfo.composition }</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Characteristics</li>
                                        <li className="right_info">Characteristics</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Design</li>
                                        <li className="right_info">Design</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Color</li>
                                        <li className="right_info">Color</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Weight</li>
                                        <li className="right_info">{ productInfo.weight } GSM</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Width</li>
                                        <li className="right_info">{ productInfo.width } CM</li>
                                    </ul>
                                    { 
                                        productInfo.highlight_wmb ?
                                        <ul className="product_detail">
                                            <li>WMB Patented design</li>
                                            <li className="right_info">Yes</li>
                                        </ul>
                                        : ""
                                    }
                                    { productInfo.highlight_eco ?
                                        <ul className="product_detail">
                                            <li>Ecological</li>
                                            <li className="right_info">Yes</li>
                                        </ul>
                                        : ""
                                    }
                                    { productInfo.highlight_new ?
                                        <ul className="product_detail">
                                            <li>New</li>
                                            <li className="right_info">Yes</li>
                                        </ul>
                                        : ""
                                    }
                                    <div className="fav_next">
                                        { 
                                            productInfo.favorite_id == 0 ?
                                                <div className="left cursor-pointer">
                                                    <p>Add to my Favourites</p>
                                                    <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                </div>
                                            : 
                                                <div className="left cursor-pointer">
                                                    <p>Remove from Favourites</p>
                                                    <span className="favorite"><img src="/assets/img/heart-fill.png" className="d-inline-block align-middle" alt="heart" /></span>
                                                </div>
                                        } 
                                        <div className="right " >
                                            <Link to={"/favourites"}> <p>Go to Favourites</p>
                                                <span className="favorite"><img src="/assets/img/arrow.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            </div>
            : ""
            }
        </>
    )
}
