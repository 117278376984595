import axios from 'axios';
const apiClient = axios.create({
    baseURL: process.env.NODE_ENV == "development" ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE,
    withCredentials: true,
});

apiClient.interceptors.response.use(
  function (response) {
    // Call was successful, don't do anything special.
    return response;
  },
  function (error) {
    switch (error.response.status) {
      
      case 401: // Not logged in
      
      localStorage.removeItem("token")
      localStorage.removeItem("id")
      localStorage.removeItem("name")
      localStorage.removeItem("email")
      localStorage.removeItem("company")
      localStorage.removeItem("role")
      localStorage.removeItem("status")
      apiClient.post('logout').then(logoutResponse=>{
          
      })
      window.location.replace("/")
      
      case 419: // Session expired
      
          localStorage.removeItem("token")
          localStorage.removeItem("id")
          localStorage.removeItem("name")
          localStorage.removeItem("email")
          localStorage.removeItem("company")
          localStorage.removeItem("role")
          localStorage.removeItem("status")
      apiClient.post('logout').then(logoutResponse=>{
        //window.location.replace("/")
      })
      window.location.replace("/")
      
      case 503: // Down for maintenance
        // Bounce the user to the login screen with a redirect back
        //window.location.reload();
        break;
      case 500:
        alert("Oops, something went wrong!  The team have been notified.");
        break;
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error);
    }
  }
);

export default apiClient;