import React,{useEffect,useContext,useState} from 'react'
import {
    Redirect,
    useParams
} from "react-router-dom";
import DispatchContext from "../../DispatchContext"
import apiClient from '../../api'
export default function AutoLogin(props)
{
    const appDispatch = useContext(DispatchContext)
    const [responseStatus, setResponseStatus] = useState("")
    var {autoTokenId} = useParams()
    useEffect(() => {
        check()
    }, [])
    function check()
    {
        apiClient.get('api/automatic_login/'+autoTokenId).then(response=>{
            console.log(response)
            if(response.data.status == 200){
                appDispatch({ type: "login", data: response.data.userInfo })
            }else{
                alert(response.data.message)
                setResponseStatus(response.data.message)
            }
        })
    }
    return(
        <>
        {props.loggedIn ? <Redirect to="/products" /> : <Redirect to="/" />}
            <div>
                <p>{responseStatus && responseStatus.length > 0 ? responseStatus : ""}</p>
            </div>
        </>
    )
}