import React,{useState,useEffect,useContext,useRef} from 'react'
import { Form,Button,Card,Accordion,Figure,Modal } from 'react-bootstrap';

import ReactAutocomplete from "react-autocomplete"

import jQuery from 'jquery'; 

// import jQuery from '../../modules/jquery'
function Test(){
    const [value, setAutovalue] = useState('')
    var countries = [
        "ActionScript",
        "AppleScript",
        "Asp",
        "BASIC",
        "C",
        "C++",
        "Clojure",
        "COBOL",
        "ColdFusion",
        "Erlang",
        "Fortran",
        "Groovy",
        "Haskell",
        "Java",
        "JavaScript",
        "Lisp",
        "Perl",
        "PHP",
        "Python",
        "Ruby",
        "Scala",
        "Scheme"
      ];

    useEffect(() => {
      
    },[])
    
    return(
        <div>
            {/*             
            <ReactAutocomplete
                items={[
                { id: 'foo', label: 'foo' },
                { id: 'bar', label: 'bar' },
                { id: 'baz', label: 'baz' },
                ]}
                shouldItemRender={(item, value) => 
                    item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={item => item.label}
                renderItem={(item, highlighted) =>
                <div
                    key={item.id}
                    style={{ backgroundColor: highlighted ? '#eee' : 'transparent'}}
                >
                    {item.label}
                </div>
                }
                value={value}
                onChange={e => setAutovalue({ value: e.target.value })}
                onSelect={value => setAutovalue({ value })}
            /> */}
        </div>
    )
}
export default Test;