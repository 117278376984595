import React,{useState} from 'react'

//Component Import
import {withRouter} from 'react-router-dom'
import Orders from './Orders'
import Account from './Account'

export default withRouter(function MyProfile(props) {
    const [isLoading, setLoading] = useState(false);
    return (
        <>
           <div className="container-fluid">
                    {/* {
                        isLoading ? 
                        <div className="loaderDiv">
                            <img src="assets/img/ajax-loader.gif" alt="process"></img>
                        </div>
                        : ""
                    } */}
                <div className="row">
                    <div className="col-md-6 p-0">
                        <Orders setLoading={setLoading} />
                    </div>
                    <div className="col-md-6 p-0 text-center">
                        <Account flashMessages = { props.setFlashMessages } setLoading={setLoading} /> 
                    </div>
                </div>
            </div>
        </>
    )
})
